import { initializeApp, type FirebaseOptions } from 'firebase/app'
import { getAuth, GoogleAuthProvider, FacebookAuthProvider, OAuthProvider } from 'firebase/auth'

export default defineNuxtPlugin((nuxtApp) => {
    const config = useRuntimeConfig()
    const firebaseConfig: FirebaseOptions = config.public.firebase
    
    const app = initializeApp(firebaseConfig)
    const auth = getAuth(app)

    const googleProvider = new GoogleAuthProvider()
    const facebookProvider = new FacebookAuthProvider()
    const appleProvider = new OAuthProvider('apple.com')

    nuxtApp.provide('auth', auth)
    nuxtApp.provide('googleProvider', googleProvider)
    nuxtApp.provide('facebookProvider', facebookProvider)
    nuxtApp.provide('appleProvider', appleProvider)
}) 