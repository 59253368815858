<script setup lang="ts">
import iconFb from '~/assets/img/fb.svg'
import iconX from '~/assets/img/x.svg'
import iconIn from '~/assets/img/in.svg'

interface NuxtError {
	statusCode: number
	message: string
}

const props = defineProps<{
	error: NuxtError
}>()

const globalStore = useGlobalStore()
const { t } = useI18n()

const config = useRuntimeConfig()

const socials = ref([
	{
		link: 'https://www.facebook.com/yourserviceae',
		img: iconFb,
	},
	{
		link: 'https://x.com/yourserviceae',
		img: iconX,
	},
	{
		link: 'https://www.instagram.com/yourservice.ae',
		img: iconIn,
	},
])

const errorContent = computed(() => {
	if (props.error.statusCode === 404) {
		return {
			title: t('Oops! Something went wrong...'),
			description: t('The page you\'re looking for can\'t be found. It may have been removed, moved, or perhaps there\'s a small typo in the address.'),
			img: '/img/img_error_404.png',
		}
	}
	return {
		title: t('Oops! Something went wrong on our end...'),
		description: t('It looks like there’s an issue on our server. We’re already working on fixing it! Please try refreshing the page later.'),
		img: '/img/img_error_500.png',
	}
})

</script>

<template>
	<div class="bg-stroke min-w-screen h-screen px-5 py-5 md:py-10 relative">
		<div class="container mx-auto h-full flex flex-col justify-between">
			<img src="/img/ys_footer_logo.svg" alt="Yourservice" class="mx-auto max-h-[34px]">

			<div class="grid md:grid-cols-2 gap-10 items-center py-12">
				<div class="max-w-[540px] order-2 md:order-1 text-center md:text-left">
					<h1 class="text-7xl font-bold">{{ error.statusCode }}</h1>
					<p class="mt-4 text-2xl font-bold">{{ errorContent.title }}</p>
					<p class="mt-4 text-gray-500">{{ errorContent.description }}</p>
					<UiButton class="w-full md:w-fit mt-4" variant="primary" size="lg" as-child>
						<NuxtLink to="/">{{ $t('Return to home') }}</NuxtLink>
					</UiButton>
				</div>
				<img class="order-1 md:order-2" :src="errorContent.img" alt="">
			</div>


			<div class="footer">
				<div class="flex gap-6 w-fit mx-auto">
					<a v-for="item in socials" :href="item.link" target="_blank">
						<Component :is="item.img" class="hover:opacity-70 transition-all" />
					</a>
				</div>
				<div v-if="config.public.playstoreLink || config.public.appstoreLink"
					class="flex items-center gap-6 mx-auto w-fit mt-4">
					<a :href="config.public.playstoreLink" target="_blank">
						<NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/googleplay.svg" />
					</a>
					<a :href="config.public.appstoreLink" target="_blank">
						<NuxtImg class="ys-app-emblem h-[35px]" src="/home_page/appstore.svg" />
					</a>
				</div>
				<p class="mt-4 text-sm text-gray-500 col-span-2 text-center">
					{{ $t('© YourService International Ltd.') }}{{ new Date().getFullYear() }}
					<template v-if="globalStore.rule_pages.privacy_page && globalStore.rule_pages.rules_page">
						<NuxtLink
							v-for="page in [globalStore.rule_pages.privacy_page && globalStore.rule_pages.rules_page]"
							class="text-black hover:text-gray-700 transition-all" :to="`/promo/${page.documentId}`">
							{{ page.name }}
						</NuxtLink>
					</template>
				</p>
			</div>
		</div>
	</div>


</template>
