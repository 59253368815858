<script setup lang="ts">
import {useUserStore} from "~/store/user.store";
import {Toaster} from "~/components/ui/toast";

const { locale } = useI18n()
const config = useRuntimeConfig()

const dir = computed(() => locale.value === 'ar' ? 'rtl' : 'ltr')
const lang = computed(() => locale.value === 'ar' ? 'ar' : 'en')

useHead({
    htmlAttrs: {
        dir: dir,
        lang: lang,
    },
    link: [
        { rel: 'canonical', href: config.public.frontUrl },
        { rel: 'apple-touch-icon', sizes: '180x180', href: '/apple-touch-icon.png' },
        { rel: 'mask-icon', color: "#000000" },
        { rel: 'icon', type: 'image/x-icon', href: '/favicon.ico' },
    ],
    titleTemplate: (titleChunk) => {
        return titleChunk ? `${titleChunk} | Yourservice.ae` : 'Yourservice.ae';
    },
})

const defaultSeoDescription = 'Discover top job opportunities and hire the best professionals in the UAE with YourService.ae. Start your journey to a successful career or find the right talent for your business today.'

useSeoMeta({
    robots: 'index, follow',
    description: defaultSeoDescription,
    
    ogImage: 'https://example.com/image.png',
    ogImageType: 'image/jpeg',
    ogSiteName: 'Yourservice.ae',
    ogLocale: 'en_US',
    ogType: 'website',
    ogUrl: config.public.frontUrl,
    ogTitle: 'Yourservice.ae',
    ogDescription: defaultSeoDescription,

    twitterCard: 'summary',
    twitterTitle: 'Yourservice.ae',
    twitterDescription: defaultSeoDescription,
    twitterImage: 'https://example.com/image.png',
    twitterImageType: 'image/jpeg',
    
    colorScheme: 'only light',
    applicationName: 'Yourservice.ae',
    themeColor: '#0C3B2E',
})



const globalStore = useGlobalStore()
const userStore = useUserStore()
globalStore.fetchSettings()

//userStore.restoreState()

if(process.client){
    setInterval(() => {
        //userStore.fetchMessageCount()
    }, 60 * 1000)
}

const isProduction = process.env.NODE_ENV === 'production'

</script>
<template>
    <Histats v-if="isProduction" />
    <NuxtLayout>
        <NuxtPage />
    </NuxtLayout>

    <Toaster />
</template>

<style>
.page-enter-active,
.page-leave-active {
    transition: all 0.4s;
}

.page-enter-from,
.page-leave-to {
    opacity: 0;
    filter: blur(1rem);
}
</style>