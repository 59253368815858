export default defineNuxtRouteMiddleware(async (to, from) => {
    const userStore = useUserStore();
    const tokenCookie = useCookie('access_token');

    // На сервере проверяем токен из куки
    if (process.server && tokenCookie.value) {
        return;
    }

    // Если пользователь авторизован, но данные не загружены и не в процессе загрузки
    if (userStore.access_token && !userStore.user && !userStore.loading) {
        try {
            await userStore.restoreState();
            // Если после восстановления состояния пользователь все еще null, значит произошла ошибка
            if (!userStore.user) {
                await userStore.logout();
                return navigateTo('/', { replace: true });
            }
        } catch (error) {
            console.error('Error restoring user state:', error);
            await userStore.logout();
            return navigateTo('/', { replace: true });
        }
    }
    
    const isLoggedIn = userStore.isAuthenticated;

    // Проверяем все условия для редиректа на главную
    const shouldRedirectToHome = 
        (!isLoggedIn && !tokenCookie.value && to.path.includes('create-account')) || 
        (!isLoggedIn && !tokenCookie.value && to.path.includes('office')) ||
        (!isLoggedIn && !tokenCookie.value && to.path.includes('order-create')) ||
        (isLoggedIn && userStore.user?.username && userStore.user?.user_role && to.path.includes('create-account')) ||
        (isLoggedIn && to.path.includes('office') && userStore.user?.user_role && !to.path.includes(userStore.user.user_role.toLowerCase())) ||
        (isLoggedIn && userStore.user?.user_role === 'MASTER' && to.path.includes('order-create'));

    if (shouldRedirectToHome) {
        return navigateTo('/', { replace: true });
    }
    

    // Проверяем необходимость редиректа на create-account
    const shouldRedirectToCreateAccount = 
        !userStore.loading &&
        (isLoggedIn || !!tokenCookie.value) && 
        (!userStore.user?.username || !userStore.user?.user_role) && 
        !to.path.includes('create-account') && 
        !to.path.includes('terms-of-service') && 
        !to.path.includes('privacy-policy');

    if (shouldRedirectToCreateAccount) {
        return navigateTo('/create-account', { replace: true });
    }
});