export const endpoints = {
    settings: {
        get: `/settings`,
    },
    auth: {
        check: `/check`,
        register: `/register`,
        partRegister: `/part-register`,
        forgotPassword: `/forgot-password`,
        setPassword: `/set-password`,
        login: `/login`,
        logout: `/logout`,
        social: `/fb-login`,
        otp: {
            send: `/otp/send`,
            verify: `/otp/verify`,
        }
    },
    portfolio: {
        filters: `/portfolios/filters`,
        search: `/portfolios/search`,
        getById: (id: string) => `/portfolios/${id}`,
    },
    tasks: {
        filters: `/tasks/available-filters`,
        search: `/tasks/search`,
        getById: (id: string) => `/tasks/${id}`,
        response: (id: string) => `/tasks/${id}/response`,
        changeState: `/tasks/change-status`, // Deprecated
        sendEvent: `/tasks/handle-response-event`,
        delete: (id: string) => `/tasks/${id}`,
        changeResponse: (id: string) => `/responses/${id}`,
    },
    chat: {
        client_chats: `/responses/get-customer-responses`,
        master_chats: `/responses/get-master-responses`,
        single_response: (id: string) => `/responses/${id}`,
        messages: (id: string) => `/messages/get-chat-messages/${id}`,
        send_message: `/messages`,
        evaluate: `/reviews`,
    },
    user: {
        get: `/users/me`,
        updateById: (id: string) => `/users/${id}`,
        delete: `/user`,
        portfolio: {
            create: `/portfolios`,
            update: (id: string) => `/portfolios/${id}`,
            delete: (id: string) => `/portfolios/${id}`,
        },
        tasks: {
            orders: `/users/orders`,
            list: `/users/tasks`,
            create: `/tasks`,
            suggest: `/tasks/suggest`,
            update: (id: string) => `/tasks/${id}`,
            delete: (id: string) => `/tasks/${id}`,
            review: (id: string) => `/tasks/${id}/review`,
            chat: {
                uploadFile: (id: string) => `/user/tasks/${id}/chat/file`,
                messages: (id: string) => `/user/tasks/${id}/chat/messages`,
            },
            actions: {
                approve: (id: string) => `/user/tasks/${id}/action/approve`,
                callOff: (id: string) => `/user/tasks/${id}/action/call-off`,
                complete: (id: string) => `/user/tasks/${id}/action/complete`,
                resume: (id: string) => `/user/tasks/${id}/action/resume`,
                return: (id: string) => `/user/tasks/${id}/action/return`,
                refuse: (id: string) => `/user/tasks/${id}/action/refuse`,
                submit: (id: string) => `/user/tasks/${id}/action/submit`,
            }
        },
        unreadMessages: `/user/unread-messages`,
        recoverPassword: '/recover-password',
        changePassword: '/save-new-password',
    },
    pages: {
        getById: (id: string) => `/pages/${id}`,
    },
    faq: {
        groups: `/article-groups`,
        singleGroup: (id: string) => `/article-groups/${id}`,
        articles: `/articles`,
        single: (id: string) =>  `/articles/${id}`,
    },
    files: {
        post: `/upload`,
        delete: (id: string) => `/upload/files/${id}`,
        deleteBulk: `/upload/files`,
    },
    promo: {
        register: `/user/register-landing`
    },
    payments: {
        createCustomer: `/payments/create-connected-account`,
        createPaymentIntent: `/payments/create-payment-intent`,
        createSetupIntent: `/payments/create-setup-intent`,
        availablePaymentMethods: `/payments/cards`,
        externalAccountCards: `/payments/account-cards`,
        createExternalAccount: `/payments/create-external-account`,
    }
}